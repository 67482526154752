$colors: (
  'black': #000000,

  'blue': #00b,
  'blue-50': #eff6ff,
  'blue-100': #dbeafe,
  'blue-200': #bfdbfe,
  'blue-300': #93c5fd,
  'blue-400': #60a5fa,
  'blue-500': #3b82f6,
  'blue-600': #2563eb,
  'blue-700': #1d4ed8,
  'blue-800': #1e40af,
  'blue-900': #1e3a8a,
  'blue-950': #172554,

  'brand-blue': #1a9fd6,
  'brand-blue-00': #f7fafc,
  'brand-blue-50': #e8f6fc,
  'brand-blue-100': #d1edf9,
  'brand-blue-200': #a4dcf3,
  'brand-blue-300': #76caed,
  'brand-blue-400': #49b9e7,
  'brand-blue-500': #1ba7e1,
  'brand-blue-600': #1686b4,
  'brand-blue-700': #106487,
  'brand-blue-800': #0b435a,
  'brand-blue-900': #05212d,
  'brand-blue-950': #031116,

  'green-50': #f0fdf4,
  'green-100': #dcfce7,
  'green-200': #bbf7d0,
  'green-300': #86efac,
  'green-400': #4ade80,
  'green-500': #22c55e,
  'green-600': #16a34a,
  'green-700': #15803d,
  'green-800': #166534,
  'green-900': #14532d,
  'green-950': #052e16,

  'grey-50': #e9edf5,

  'orange-50': #fff7ed,
  'orange-100': #ffedd5,
  'orange-200': #fed7aa,
  'orange-300': #fdba74,
  'orange-400': #fb923c,
  'orange-500': #f97316,
  'orange-600': #ea580c,
  'orange-700': #c2410c,
  'orange-800': #9a3412,
  'orange-900': #7c2d12,
  'orange-950': #431407,

  'neutral-50': #fafafa,
  'neutral-100': #f5f5f5,
  'neutral-200': #e5e5e5,
  'neutral-300': #d4d4d4,
  'neutral-400': #a3a3a3,
  'neutral-500': #737373,
  'neutral-600': #525252,
  'neutral-700': #404040,
  'neutral-800': #262626,
  'neutral-900': #171717,
  'neutral-950': #0a0a0a,

  'red-50': #fef2f2,
  'red-100': #fee2e2,
  'red-200': #fecaca,
  'red-300': #fca5a5,
  'red-400': #f87171,
  'red-500': #ef4444,
  'red-600': #dc2626,
  'red-700': #b91c1c,
  'red-800': #991b1b,
  'red-900': #7f1d1d,
  'red-950': #450a0a,

  'status-success': #36a53b,
  'status-success-light': #edf7ed,
  'status-error-light': #fdeeee,
  'status-error': #ea5455,
  'status-warning': #ed6c02,
  'status-warning-light': #fff4e5,

  'transparent-black-25': rgba(0, 0, 0, 0.25),
  'transparent-black-50': rgba(0, 0, 0, 0.5),
  'transparent-black-75': rgba(0, 0, 0, 0.75),
  'white': #ffffff,

  'yellow-50': #fefce8,
  'yellow-100': #fef9c3,
  'yellow-200': #fef08a,
  'yellow-300': #fde047,
  'yellow-400': #facc15,
  'yellow-500': #eab308,
  'yellow-600': #ca8a04,
  'yellow-700': #a16207,
  'yellow-800': #854d0e,
  'yellow-900': #713f12,
  'yellow-950': #422006,

  'neutral1': #0f1a2d,
  'neutral2': #404d61,
  'neutral3': #808792,
  'neutral3-5': #aab1b7,
  'neutral4': #809fb8,
  'neutral5': #b6bdc3,
  'neutral6': #e8eaef,
  'neutral7': #f8f8fa,
  'neutral8': #fafafb,
  'neutral9': #ffffff,
);

:root {
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }

  --mat-datepicker-calendar-date-selected-state-background-color: var(--brand-blue);

  --padding-card-products: 8px 4px;

  @media screen and (min-width: 576px) {
    --padding-card-products: 56px 24px;
  }
}

@each $name, $value in $colors {
  .color--#{$name} {
    color: $value;
  }

  .color--#{$name}--f {
    color: $value !important;
  }

  .bg--#{$name} {
    background-color: $value;
  }

  .bg--#{$name}--f {
    background-color: $value !important;
  }
}

.neutral-500 {
  color: var(--neutral-500);
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.flex-1 {
  flex: 1;
}

.min-w-fit {
  min-width: fit-content !important;
}

.text-pre-line {
  white-space: pre-line;
}
