.login-logo {
    display: flex;
    justify-content: center;
    img {
      max-height: 100px;
    }
  }
  .brand-title {
    text-align: center;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 35.88px;
  }
  
  .forgot-button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--brand-blue-500);
    cursor: pointer;
  }
  
  .login-body-wrapper {
    // min-height: 100vh;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../assets/images/bg-login.svg");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  
  .login-layout {
    min-height: 100vh;
    display: flex;
  
    .login-center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
  
      z-index: 1;
      .login-box {
        display: flex;
        // align-items: center;
        flex-direction: column;
        width: 520px;
        border-radius: 8px;
        padding: 60px 40px 60px 40px;
        gap: 30px;
        background-color: #ffffff;
        box-shadow: 0px 4px 12px 2px #0000001f;
      }
    }
  }
  
  /* Responsive */
  @media only screen and (max-width: 1000px) {
  
  }
  
  @media only screen and (max-width: 600px) {
    .login-body-wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      width: 100%;
      height: 100%;
  
      &::before {
        position: fixed;
        background: linear-gradient(
            0deg,
            rgba(7, 54, 149, 0.15) 51.94%,
            rgba(7, 54, 149, 0) 108.59%
          ),
          url("../assets/images/bg-login.svg");
        background-size: cover;
        background-position: 39% 0%;
      }
    }
  
    .login-layout {
      justify-content: center;
      padding-right: 0;
      .login-box {
        width: 100%;
        max-width: 320px;
        padding: 20px 20px 20px 20px;
      }
    }
  }
  