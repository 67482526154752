.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  height: 40px;
  font-size: 0.875rem;
  text-decoration: none;
}

.btn-primary {
  border-radius: 8px;
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--brand-blue);
  --bs-btn-border-color: var(--brand-blue);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--brand-blue-500);
  --bs-btn-hover-border-color: var(--brand-blue-500);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--brand-blue-500);
  --bs-btn-active-border-color: var(--brand-blue-500);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-primary {
  border-radius: 8px;
  --bs-btn-color: var(--brand-blue);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--brand-blue);
  --bs-btn-hover-color: var(--brand-blue);
  --bs-btn-hover-bg: var(--brand-blue-50);
  --bs-btn-hover-border-color: var(--brand-blue-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--brand-blue);
  --bs-btn-active-bg: var(--brand-blue-50);
  --bs-btn-active-border-color: var(--brand-blue-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-primary-dark {
  border-radius: 8px;
  --bs-btn-color: var(--brand-blue-700);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--brand-blue-700);
  --bs-btn-hover-color: var(--brand-blue-700);
  --bs-btn-hover-bg: var(--brand-blue-50);
  --bs-btn-hover-border-color: var(--brand-blue-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--brand-blue-700);
  --bs-btn-active-bg: var(--brand-blue-50);
  --bs-btn-active-border-color: var(--brand-blue-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-secondary {
  border-radius: 8px;
  --bs-btn-color: var(--brand-blue-700);
  --bs-btn-bg: var(--brand-blue-50);
  --bs-btn-border-color: var(--brand-blue-50);
  --bs-btn-hover-color: var(--brand-blue-700);
  --bs-btn-hover-bg: var(--brand-blue-50);
  --bs-btn-hover-border-color: var(--brand-blue-50);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--brand-blue-700);
  --bs-btn-active-bg: var(--brand-blue-50);
  --bs-btn-active-border-color: var(--brand-blue-50);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-secondary {
  border-radius: 8px;
  --bs-btn-color: var(--brand-blue-700);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--brand-blue-700);
  --bs-btn-hover-color: var(--brand-blue-700);
  --bs-btn-hover-bg: var(--brand-blue-50);
  --bs-btn-hover-border-color: var(--brand-blue-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--brand-blue-700);
  --bs-btn-active-bg: var(--brand-blue-50);
  --bs-btn-active-border-color: var(--brand-blue-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-secondary-bg-tran {
  border-radius: 8px;
  --bs-btn-color: var(--brand-blue-700);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--brand-blue-700);
  --bs-btn-hover-color: var(--brand-blue-700);
  --bs-btn-hover-bg: var(--brand-blue-50);
  --bs-btn-hover-border-color: var(--brand-blue-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--brand-blue-700);
  --bs-btn-active-bg: var(--brand-blue-50);
  --bs-btn-active-border-color: var(--brand-blue-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-dark {
  border-radius: 8px;
  --bs-btn-color: var(--neutral-950);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--neutral-400);
  --bs-btn-hover-color: var(--neutral-950);
  --bs-btn-hover-bg: var(--neutral-100);
  --bs-btn-hover-border-color: var(--neutral-400);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--neutral-950);
  --bs-btn-active-bg: var(--neutral-100);
  --bs-btn-active-border-color: var(--neutral-400);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-danger {
  border-radius: 8px;
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--red-600);
  --bs-btn-border-color: var(--red-600);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--red-700);
  --bs-btn-hover-border-color: var(--red-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--red-700);
  --bs-btn-active-border-color: var(--red-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-outline-danger {
  border-radius: 8px;
  --bs-btn-color: var(--red-600);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--red-600);
  --bs-btn-hover-color: var(--red-700);
  --bs-btn-hover-bg: var(--white);
  --bs-btn-hover-border-color: var(--red-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--red-700);
  --bs-btn-active-bg: var(--white);
  --bs-btn-active-border-color: var(--red-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-orange {
  border-radius: 8px;
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--orange-400);
  --bs-btn-border-color: var(--orange-400);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--orange-500);
  --bs-btn-hover-border-color: var(--orange-500);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--orange-500);
  --bs-btn-active-border-color: var(--orange-500);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-success {
  border-radius: 8px;
  --bs-btn-color: var(--white);
  --bs-btn-bg: var(--green-600);
  --bs-btn-border-color: var(--green-600);
  --bs-btn-hover-color: var(--white);
  --bs-btn-hover-bg: var(--green-700);
  --bs-btn-hover-border-color: var(--green-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--white);
  --bs-btn-active-bg: var(--green-700);
  --bs-btn-active-border-color: var(--green-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-success-danger {
  border-radius: 8px;
  --bs-btn-color: var(--green-600);
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: var(--green-600);
  --bs-btn-hover-color: var(--green-700);
  --bs-btn-hover-bg: var(--white);
  --bs-btn-hover-border-color: var(--green-700);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--green-700);
  --bs-btn-active-bg: var(--white);
  --bs-btn-active-border-color: var(--green-700);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--neutral-500);
  --bs-btn-disabled-bg: var(--neutral-200);
  --bs-btn-disabled-border-color: var(--neutral-200);
}

.btn-transparent {
  --bs-btn-color: transparent;
  --bs-btn-border-width: 0;
}

.btn-transparent-outline {
  --bs-btn-border-width: 0;
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
}

.btn-icon {
  width: 36px;
  height: 36px;
}

.btn-icon-sm {
  width: 24px;
  height: 24px;
}

.btn-icon-mn {
  width: 16px;
  height: 16px;
}

.btn-md {
  min-width: 100px;
}

.min-w-90px {
  min-width: 90px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-106px {
  min-width: 106px;
}

.min-w-120px {
  min-width: 120px;
}

.min-w-125px {
  min-width: 125px;
}

.min-w-160px {
  min-width: 160px;
}

.h-36px {
  height: 36px;
}

.w-fit {
  width: fit-content;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  background: transparent;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.input-number-fake {
  position: relative;
  .btn-num-right {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 32px;
    gap: 2px;
    padding: 0;
    button {
      border: 0 !important;
      outline: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      height: 15px;
      display: flex;
      background: transparent !important;
      position: relative;
      border-radius: 4px;
      &:hover {
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 4px;

          &:not(:disabled) {
            background: rgb(164 164 164 / 30%);
          }
        }
      }
    }
  }
}
