.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 8px;
}

.mdc-text-field--focused {
  border-color: var(--brand-blue) !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px !important;
  &:not(.mdc-evolution-chip--disabled) {
    --mdc-chip-elevated-container-color: var(--brand-blue-00, #f7fafc);
  }
}

.mat-mdc-chip-input {
  height: 40px !important;
}

.mat-mdc-form-field {
  .mat-mdc-form-field-flex {
    height: 100%;
    .mat-mdc-form-field-infix {
      --mat-form-field-container-height: 40px;
      height: 100%;
      width: 100%;
      .mat-mdc-select {
        height: 100%;
        .mat-mdc-select-trigger {
          height: 100%;
        }
        .mat-mdc-select-placeholder {
          color: var(--neutral-400);
        }
      }
    }
  }

  &.input {
    .mat-mdc-text-field-wrapper {
      height: 40px !important;
    }
  }

  &.select,
  &.mat-mdc-paginator-page-size-select {
    .mat-mdc-text-field-wrapper {
      height: 40px !important;
    }
  }

  &.select-bank {
    .mat-mdc-text-field-wrapper {
      height: 65px !important;
    }
  }

  &.textarea {
    .mat-mdc-text-field-wrapper {
      padding: 8px 8px 8px 16px;
    }
  }
}

// .mat-datepicker-toggle {
//   button.mat-mdc-button-base {
//     margin-top: -15px;
//   }
// }

.remaining-text {
  width: 100%;
  font-size: 14px;
  color: var(--neutral-500);
  text-align: end;
  margin-top: -20px;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix,
.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  margin-top: 8px;
}

.mdc-evolution-chip-set__chips {
  .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    margin-top: 0;
  }
}

.mat-mdc-form-field {
  width: 100%;
  --mat-select-invalid-arrow-color: var(--red-600);
  --mdc-outlined-text-field-error-caret-color: var(--red-600);
  --mat-form-field-error-text-color: var(--red-600);
  --mdc-outlined-text-field-error-outline-color: var(--red-600);
  --mdc-outlined-text-field-error-focus-outline-color: var(--red-600);
}

.text-danger {
  color: var(--red-600) !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-paginator-page-size {
  align-items: center !important;
}

.iconSuffix {
  --mdc-icon-button-icon-size: initial;
}

// .mat-mdc-paginator {
//   .mat-mdc-select-value {
//     margin-top: 8px;
//   }
// }

// .mat-mdc-select-value {
//   margin-top: 2px;
// }

// .mat-mdc-select-arrow svg {
//   margin-top: 3px;
// }

.mdc-checkbox {
  --mdc-checkbox-selected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  --mdc-checkbox-unselected-pressed-icon-color: var(--brand-blue-700);
  --mdc-checkbox-unselected-focus-icon-color: var(--brand-blue-700);
  --mdc-checkbox-unselected-icon-color: var(--brand-blue-700);
  --mdc-checkbox-unselected-hover-icon-color: var(--brand-blue-700);
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--brand-blue-700) !important;
  background-color: var(--brand-blue-700) !important;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[data-indeterminate='true'] ~ .mdc-checkbox__background {
  transition: none !important;
}

.mat-mdc-select-arrow {
  svg {
    display: none !important;
  }
  &:after {
    content: '';
    fill: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-left: 24px solid transparent !important;
    border-right: none !important;
    border-top: 24px solid transparent !important;
    border-image-source: url('/assets/images/icons/icon-select.svg') !important;
    border-image-repeat: stretch !important;
  }
}

.mdc-list-item--selected {
  --mat-option-selected-state-label-text-color: var(--brand-blue);
  &:not(.mdc-list-item--disabled, :hover) {
    background-color: transparent !important;
  }
}

/* Placeholder */
.mat-mdc-form-field {
  .mdc-text-field--outlined {
    &:not(.mdc-text-field--disabled) {
      .mdc-text-field__input {
        &::placeholder {
          color: var(--neutral-400);
        }
      }
    }
  }
}

.mat-mdc-form-field {
  .mdc-text-field--outlined.mdc-text-field--disabled {
    background-color: var(--neutral-100);

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--neutral-400) !important;
    }
  }
}

/* Checkbox in Table */
.checkbox-table {
  --mdc-checkbox-state-layer-size: 0;
  .mdc-checkbox {
    margin: 0;

    .mat-mdc-checkbox-touch-target {
      width: 18px;
      height: 18px;
    }
  }
}

/* Slide Toggle */
.mat-mdc-slide-toggle {
  --mdc-switch-unselected-focus-state-layer-color: var(--neutral-300);
  --mdc-switch-unselected-handle-color: var(--white);
  --mdc-switch-unselected-hover-state-layer-color: var(--neutral-300);
  --mdc-switch-unselected-pressed-state-layer-color: var(--neutral-300);
  --mdc-switch-unselected-focus-handle-color: var(--white);
  --mdc-switch-unselected-hover-handle-color: var(--white);
  --mdc-switch-unselected-pressed-handle-color: var(--white);
  --mdc-switch-unselected-focus-track-color: var(--neutral-300);
  --mdc-switch-unselected-hover-track-color: var(--neutral-300);
  --mdc-switch-unselected-pressed-track-color: var(--neutral-300);
  --mdc-switch-unselected-track-color: var(--neutral-300);
  --mdc-switch-track-width: 40px;
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-shape: 13px;
  --mat-switch-unselected-handle-size: 18px;
  --mat-switch-selected-handle-size: 18px;
  --mdc-switch-handle-width: 18px;
  --mat-switch-unselected-handle-horizontal-margin: 0 0 0 1px;
  --mat-switch-selected-handle-horizontal-margin: 0 0 0 -1px;

  &.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: var(--green-600);
    --mdc-switch-selected-handle-color: var(--white);
    --mdc-switch-selected-hover-state-layer-color: var(--green-600);
    --mdc-switch-selected-pressed-state-layer-color: var(--green-600);
    --mdc-switch-selected-focus-handle-color: var(--white);
    --mdc-switch-selected-hover-handle-color: var(--white);
    --mdc-switch-selected-pressed-handle-color: var(--white);
    --mdc-switch-selected-focus-track-color: var(--green-600);
    --mdc-switch-selected-hover-track-color: var(--green-600);
    --mdc-switch-selected-pressed-track-color: var(--green-600);
    --mdc-switch-selected-track-color: var(--green-600);
  }

  .mdc-switch {
    &:enabled {
      + .mdc-label {
        padding-left: 0.5rem;
      }
    }
  }
}

/* Label */
.label {
  font-size: 0.875rem;
  color: var(--neutral-950);
}

.form-label {
  font-size: 0.875rem;
}

.view-data-text {
  font-size: 0.875rem;
  color: var(--neutral-950);
}

/* mat-menu-item */
.mat-menu-item {
  padding: 8px 16px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: var(--brand-blue-50);
  }
}

/* Mat Hint */
.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-hint-wrapper {
    padding: 0;

    .mat-mdc-form-field-hint {
      font-size: 0.875rem;
      color: var(--neutral-500);
    }
  }
}

.hint-text {
  font-size: 0.875rem;
  color: var(--neutral-500);
}

/* Radio Box */
.radio-button-wrapper {
  .mat-mdc-radio-button {
    &.mat-accent {
      --mdc-radio-disabled-selected-icon-color: black;
      --mdc-radio-disabled-unselected-icon-color: black;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: var(--neutral-500);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-selected-focus-icon-color: var(--brand-blue-700);
      --mdc-radio-selected-hover-icon-color: var(--brand-blue-700);
      --mdc-radio-selected-icon-color: var(--brand-blue-700);
      --mdc-radio-selected-pressed-icon-color: var(--brand-blue-700);
      --mat-radio-ripple-color: black;
      --mat-radio-checked-ripple-color: var(--brand-blue-700);
      --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
    }
  }
}

.mat-pseudo-checkbox-full {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--brand-blue-700);
  --mat-full-pseudo-checkbox-unselected-pressed-icon-color: var(--brand-blue-700);
  --mat-full-pseudo-checkbox-unselected-focus-icon-color: var(--brand-blue-700);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--brand-blue-700);
  --mat-full-pseudo-checkbox-unselected-hover-icon-color: var(--brand-blue-700);
}

.mat-mdc-option-multiple {
  --mat-option-selected-state-label-text-color: var(--neutral-950);
}

.checkbox-option-all {
  margin-left: -11px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Mat Menu */
.print-menu-wrapper {
  .mat-mdc-menu-content {
    min-width: 180px;
  }
}

.time-picker-menu-wrapper {
  .mat-mdc-menu-content {
    min-width: 200px;
  }
}

.progress-bar {
  border-radius: 8px;
  background-color: var(--brand-blue-600);
}

.progress,
.progress-stacked {
  --bs-progress-height: 8px;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: var(--brand-blue-600);
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--neutral-200);
  border-radius: var(--bs-progress-border-radius);
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--brand-blue-600) !important;
}

.hide-error {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.div-input-disabled {
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-400);
  border-radius: 8px;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: var(--mat-select-disabled-trigger-text-color);
}
