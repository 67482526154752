.scope-print {
  display: none;
}

.scope-print-test {
  display: block;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 99999;
  //   background-color: #ffffff;
}

// app-root {
//   display: none;
// }

.scope-print,
.view-print {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.94px;

  .pages {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 100vw;
    position: relative;
  }

  .address-page-item {
    background-color: var(--white);
    // flex: 0 0 31%;
    flex: 0 0 378px;
    height: 567px;
    max-height: 567px;
    padding: 16px;
    font-size: 12px;
    color: var(--black);
    position: relative;
  }

  .address-page-footer {
    padding: 0 16px 16px 16px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .cover-sheet-label {
    flex: 0 0 25px;
  }

  .font-9 {
    font-size: 9px;
  }

  .font-13 {
    font-size: 13px;
  }

  .font-bold {
    font-weight: 600;
  }

  .address-text {
    min-height: 36px;
  }

  .customer-info-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .table-address-page-wrapper {
    .table-address-page {
      tr {
        vertical-align: top;

        th,
        td {
          padding: 0 8px 8px 0;
        }
      }
    }
  }

  .a4-page-item {
    background-color: var(--white);
    position: relative;
    padding: 48px;
    display: flex;
    flex-direction: column;
  }

  .a4-page-footer {
    padding: 0 48px 48px 48px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .table-border-wrapper {
    margin-bottom: 24px;

    .table-bordered {
      &.table-top-wrapper {
        table-layout: fixed;
      }

      tr {
        border-color: var(--neutral-950);

        th,
        td {
          background-color: var(--white);
          border-color: var(--neutral-950);
          color: var(--black);
          padding: 16px;
        }

        th {
          position: static;
          font-size: 14px;
          font-weight: 600;
        }

        td {
          font-size: 12px;
        }
      }
    }

    .address-text {
      min-height: 48px;
    }
  }

  .total-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    row-gap: 4px;
    font-size: 12px;
    color: var(--black);

    .total-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 280px;
      padding: 8px 0;
      border-bottom: 1px solid var(--neutral-200);
    }

    // &.receipt {
    //   font-size: 16px;
    // }
  }

  .table-address {
    width: 100%;
    th {
      padding: 4px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }
    td {
      padding: 4px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }

    tr > th:first-child,
    td:first-child {
      padding-left: 0 !important;
    }
    tr > th:last-child,
    td:last-child {
      padding-right: 0 !important;
    }
  }

  .table-receipt {
    width: 100%;
    th {
      padding: 12px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }
    td {
      padding: 12px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }
  }

  .table-tax {
    width: 100%;
    th {
      padding: 12px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }
    td {
      padding: 12px !important;

      font-family: Kanit;
      font-size: 12px !important;
      line-height: 17.94px;
    }
  }

  .header-section {
    margin-bottom: 24px;
    font-size: 12px;
    color: var(--neutral-950);

    .header-title {
      font-size: 20px;
      font-weight: 600;
    }

    .company-name {
      font-size: 14px;
      font-weight: 600;
    }

    .right-side-info {
      flex: 0 0 210px;
    }
  }

  .header-info-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--neutral-950);
    width: 100%;
    margin-top: 32px;

    .header-info-left {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      flex: 1;
      max-width: 430px;

      .header-info {
        .header-label {
          flex: 0 0 220px;
        }
      }
    }

    .header-info-right {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      flex: 0 0 260px;
      max-width: 260px;

      .header-info {
        .header-label {
          flex: 0 0 100px;
        }
      }
    }

    .header-info {
      display: flex;
    }

    .header-text {
      flex: 1;
    }
  }

  .tax-invoice-header {
    margin-bottom: 24px;
  }

  .summary-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    .note-wrapper {
      width: 100%;
      .note {
        font-size: 12px;
        color: var(--black);
      }

      .total-text {
        font-size: 12px;
        color: var(--black);
        width: 100%;
        height: 100%;
        padding-top: 34px;
        padding-left: 80px;
      }
    }
  }

  .box-expand {
    flex: 1;
  }

  .signature-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 120px;

    .signature-item {
      flex: 0 0 166px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      text-align: center;
    }
  }

  .line {
    border-bottom: 1px solid var(--black);
    margin: 0;
  }

  .line-grey {
    border-bottom: 1px solid var(--neutral-200);
    padding-top: 24px;
    margin: 0;
  }
}

@page:left {
  @bottom-left {
    content: 'Page ' counter(page) ' of ' counter(pages);
  }
}

#page-number {
  position: fixed;
  bottom: 0;
  right: 5px;
  background-color: #ffffff;
}

#page-number:after {
  counter-increment: page_number;
  // background-color: #ffffff;
  content: 'Page ' counter(page_number);
}

.page-break {
  // counter-reset: page_number;
  // page-break-inside: avoid;
  // clear: both;
  // page-break-after: always;
  page-break-before: always;
  page-break-inside: avoid;
  // #page-number {
  //   z-index: 1;
  // }
}

// .table-break {
//   page-break-inside: auto;
// }
// .tr-breck {
//   page-break-inside: avoid;
//   page-break-after: auto;
// }

.page-footer {
  // position: fixed;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1;
  // &:after {
  //   counter-increment: page_number;
  //   content: 'Page ' counter(page_number);
  // }
}

.table-auto-cut tr.auto-number {
  counter-increment: rowNumber;
}

.table-auto-cut tr.auto-number td:first-child::before {
  content: counter(rowNumber);
}

@media print {
  app-root {
    display: none;
  }

  .scope-print {
    display: block;
  }

  .page-break {
    margin-top: 0 !important;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .pages {
    gap: 0 !important;
  }

  @page {
    // size: A4;
    // margin: 1cm;
    margin: 0mm;
    padding: 0;
    size: auto;

    // @bottom-left {
    //   content: counter(page) ' of ' counter(pages);
    // }
    // @bottom-center {
    //   counter-increment: page;
    //   content: 'Page ' counter(page) '/' counter(pages);
    //   margin-bottom: 1cm;
    // }
  }

  body {
    counter-reset: page_number;
    // margin: 0;
    // padding: 0;
    // overflow: hidden !important;
  }

  @page :footer {
    // display: none;
  }

  @page :header {
    // display: none;
  }

  @page :blank {
    @top-center {
      content: 'This page is intentionally left blank.';
    }
  }
}
