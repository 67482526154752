/* Tabs in Page View */
.tabs-wrapper {
  .mat-mdc-tab-group {
    --mat-tab-header-active-focus-indicator-color: var(--brand-blue-600);
    --mat-tab-header-active-ripple-color: transparent;

    &.mat-mdc-tab-group-stretch-tabs {
      >.mat-mdc-tab-header {
        .mat-mdc-tab {
          flex-grow: 0;
        }
      }
    }
  }

  .mat-mdc-tab-labels {
    column-gap: 12px;

    .mat-mdc-tab {
      padding: 0 1.25rem;
      height: 56px;
      font-weight: 400;

      &:hover {
        .mdc-tab__ripple {
          &::before {
            background: transparent;
          }
        }
      }

      &.mdc-tab--active {
        .mdc-tab-indicator {
          background: var(--white);
        }

        .mdc-tab__text-label {
          color: var(--brand-blue);
        }

        &:hover {
          .mdc-tab-indicator__content--underline {
            background: var(--brand-blue-50);
            border-color: var(--brand-blue-600);
          }
        }
      }

      .mdc-tab__text-label {
        color: var(--neutral-950);
      }

      .mdc-tab-indicator {
        border-radius: 0.5rem 0.5rem 0 0;
        background: var(--neutral-200);

        .mdc-tab-indicator__content--underline {
          width: calc(100% - 2.5rem);
          border-width: 3px;
          border-color: var(--brand-blue);
        }
      }
    }
  }
}

/* Tabs in Modal */
.tabs-modal-wrapper {

  .mat-mdc-tab-group {
    --mat-tab-header-active-focus-indicator-color: var(--brand-blue-600);
    --mat-tab-header-active-ripple-color: transparent;

    &.mat-mdc-tab-group-stretch-tabs {
      >.mat-mdc-tab-header {
        .mat-mdc-tab {
          flex-grow: 0;
        }
      }
    }
  }

  .mat-mdc-tab-labels {
    column-gap: 4px;
    border-bottom: 1px solid var(--neutral-200);

    .mat-mdc-tab {
      padding: 0 1.25rem; // 0 1.25rem
      height: 56px;
      font-weight: 400;

      &:hover {
        .mdc-tab__ripple {
          &::before {
            background: transparent;
          }
        }
      }

      &.mdc-tab--active {
        .mdc-tab-indicator {
          background: var(--white);
        }

        .mdc-tab__text-label {
          color: var(--brand-blue);
        }

        &:hover {
          .mdc-tab-indicator__content--underline {
            background: var(--brand-blue-50);
            border-color: var(--brand-blue-600);
          }
        }
      }

      .mdc-tab__text-label {
        color: var(--neutral-950);
      }

      .mdc-tab-indicator {
        border-radius: 0.5rem 0.5rem 0 0;
        background: var(--white);

        .mdc-tab-indicator__content--underline {
          width: calc(100% - 2.5rem);
          border-width: 3px;
          border-color: var(--brand-blue);
        }
      }
    }
  }
}

/* Tabs in Modal Tier */
.tabs-modal-tier-wrapper {
  .mat-mdc-tab-group {
    --mat-tab-header-active-focus-indicator-color: var(--brand-blue-600);
    --mat-tab-header-active-ripple-color: transparent;

    &.mat-mdc-tab-group-stretch-tabs {
      >.mat-mdc-tab-header {
        .mat-mdc-tab {
          flex-grow: 1;
        }
      }
    }
  }

  .mat-mdc-tab-labels {
    column-gap: 4px;
    border-bottom: 1px solid var(--neutral-200);
    justify-content: space-between;

    .mat-mdc-tab {
      // padding: 0 1.25rem; // 0 1.25rem
      height: 56px;
      font-weight: 400;

      &:hover {
        .mdc-tab__ripple {
          &::before {
            background: transparent;
          }
        }
      }

      &.mdc-tab--active {
        .mdc-tab-indicator {
          background: var(--white);
        }

        .mdc-tab__text-label {
          color: var(--brand-blue);
        }

        &:hover {
          .mdc-tab-indicator__content--underline {
            background: var(--brand-blue-50);
            border-color: var(--brand-blue-600);
          }
        }
      }

      .mdc-tab__text-label {
        color: var(--neutral-950);
      }

      .mdc-tab-indicator {
        border-radius: 0.5rem 0.5rem 0 0;
        background: var(--white);

        .mdc-tab-indicator__content--underline {
          border-width: 3px;
          border-color: var(--brand-blue);
        }
      }
    }
  }
}

/* Tabs in Sub */
.tabs-sub-wrapper {
  .mat-mdc-tab-group {
    --mat-tab-header-active-focus-indicator-color: var(--brand-blue-600);
    --mat-tab-header-active-ripple-color: transparent;

    &.mat-mdc-tab-group-stretch-tabs {
      >.mat-mdc-tab-header {
        .mat-mdc-tab {
          flex-grow: 0;
        }
      }
    }
  }

  .mat-mdc-tab-labels {
    column-gap: 12px;
    border-bottom: 1px solid var(--neutral-200);

    .mat-mdc-tab {
      padding: 0 1.25rem;
      height: 56px;
      font-weight: 400;

      &:hover {
        .mdc-tab__ripple {
          &::before {
            background: transparent;
          }
        }
      }

      &.mdc-tab--active {
        .mdc-tab-indicator {
          background: var(--white);
        }

        .mdc-tab__text-label {
          color: var(--brand-blue);
        }

        &:hover {
          .mdc-tab-indicator__content--underline {
            background: var(--brand-blue-50);
            border-color: var(--brand-blue-600);
          }
        }
      }

      .mdc-tab__text-label {
        color: var(--neutral-950);
      }

      .mdc-tab-indicator {
        border-radius: 0.5rem 0.5rem 0 0;
        background: var(--white);

        .mdc-tab-indicator__content--underline {
          width: 100%;
          border-width: 3px;
          border-color: var(--brand-blue);
        }
      }
    }
  }
}