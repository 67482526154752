.page-content {
  padding: 24px;
  background-color: var(--neutral-100);
  height: calc(100vh - 56px);
  overflow: auto;
}

.page-main-content {
  padding: 24px 96px;
  background-color: var(--neutral-100);
  height: calc(100vh - 56px);
  overflow: auto;

  @media only screen and (max-width: 1000px) {
    padding: 24px 24px;
  }
}

.page-content-inner {
  background-color: var(--white);
  padding: 1.25rem;
  border-radius: 0.5rem;
  // min-height: calc(100vh - 183px);
}

.page-main-sub-content {
  background-color: var(--neutral-100);
  height: calc(100vh - 56px);
  overflow: auto;

  .box-content {
    max-width: 820px;
    margin: auto;
  }

  @media only screen and (max-width: 1000px) {
    padding: 24px 24px;
  }
}

.page-content-min-height {
  min-height: calc(100vh - 183px);
}

.title-wrapper {
  display: flex;
  font-size: 1.25rem;
  color: var(--neutral-950);
  margin-bottom: 1.25rem;
}

.title-blue {
  font-size: 1rem;
  color: var(--brand-blue);
  font-weight: 600;
}

.title-filter {
  height: 40px;
  display: flex;
  align-items: center;
}

.tabs-content-wrapper {
  background-color: var(--white);
  padding: 1.25rem;
  border-radius: 0 0.5rem 0.5rem;
}

.tabs-content-wrapper-detail {
  background-color: var(--white);
  padding: 1.25rem;
  border-radius: 0 0.5rem 0 0;
}

.top-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;

  .top-content-left {
    display: flex;
    align-items: center;
    row-gap: 1rem;
    column-gap: 0.75rem;
    flex-wrap: wrap;
  }

  .top-content-right {
    display: flex;
    align-items: center;
    row-gap: 1rem;
    column-gap: 0.75rem;
    flex-wrap: wrap;
  }
}

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  margin-bottom: 0.25rem;

  .breadcrumb-text,
  .breadcrumb-separator {
    font-size: 0.875rem;
    color: var(--neutral-500);
  }

  .breadcrumb-link {
    cursor: pointer;
  }
}

.action-button-wrapper {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.data-not-found-wrapper {
  position: sticky;
  left: 0;
  font-size: 0.875rem;
  color: var(--neutral-950);
  display: flex;
  justify-content: center;
  margin: 1.25rem 0;
  padding: 1rem 0;
  background-color: var(--neutral-50);
}

.data-not-found {
  width: 100%;
  font-size: 0.875rem;
  background-color: var(--neutral-50);
  color: var(--neutral-950);
  margin: 1.25rem 0;
  padding: 1rem 0;
}

.line {
  border-bottom: 1px solid var(--neutral-200);
  margin: 0 -1.25rem 1.25rem;
}

.cursor-pointer {
  cursor: pointer;
}
